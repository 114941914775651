import { useTheme, makeStyles } from '@mui/styles';
import { useState } from 'react';
import toast from 'react-hot-toast';
import {
  useAuth,
  useAuthManager,
} from '../../../../components/auth/AuthProvider';
import Toggler from '../../../../components/CPSwitch';
import { useProfileContext } from '../../../passportData/context';
import { postPassport } from '../../../../scripts';
import {
  validateAgainstSchema,
  versionNumbers,
  CURRENT_SCHEMA_VERSION,
} from '../../../../utils/schemaValidation';
import styles from './styles';
import Button from '../../button';
import { isServiceEnabled } from '../../../../services';
import TitleInfo from '../../../../components/Title';
import { Grid, IconButton, Box, Typography } from '@mui/material';
import ToolTip from '../../../../icons/atomsTooltip.js';
import useDisclosure from '../../../../hooks/useDisclosure';
import AboutPublicPages from '../../../modals/AboutPublicPages.js';

const useStyles = makeStyles(styles);

const PublicPages = ({ back, next, artistProfiles, personalData }) => {
  const theme = useTheme();
  const { dispatch } = useProfileContext();
  const classes = useStyles();
  const authManager = useAuthManager();
  const { user } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [isRedirecting, setRedirecting] = useState(false);
  const {
    isOpen: isAboutPublicPagesOpen,
    onOpen: onAboutPublicPagesOpen,
    onClose: onAboutPublicPagesClose,
  } = useDisclosure(false);

  const handleToggle = (artistProfile) => {
    if (isServiceEnabled(artistProfile, 'publicPage')) {
      dispatch({
        type: 'PATCH_PROFILE',
        payload: {
          id: artistProfile.id,
          enabledServices: [],
        },
      });
    } else {
      dispatch({
        type: 'PATCH_PROFILE',
        payload: {
          id: artistProfile.id,
          enabledServices: [{ name: 'publicPage' }],
        },
      });
    }
  };

  const handleSubmit = async () => {
    const jwtToken = await authManager.getJwtToken().catch(() => null);
    const passportToSubmit = {
      artistProfiles,
      email: user.email,
      test_profile: false,
      version: versionNumbers[CURRENT_SCHEMA_VERSION],
      personalData,
    };

    const { valid, error } = validateAgainstSchema(
      passportToSubmit,
      'passport'
    );
    if (!valid) {
      toast.error('Cannot save profile: invalid: ' + error);
      return;
    }

    setLoading(true);
    try {
      await postPassport(passportToSubmit, jwtToken);
      toast.success('Profile created successfully');
      setRedirecting(true);
      window.location.reload();
    } catch (error) {
      toast.error(`Error Creating Profile ${error.message}`);
    }
    setLoading(false);
  };

  return (
    <div>
      <AboutPublicPages
        isOpen={isAboutPublicPagesOpen}
        onClose={onAboutPublicPagesClose}
      />
      <Grid mb={2} container justifyContent="space-between" direction="row">
        <Grid item>
          <TitleInfo title="Public Pages" />
        </Grid>
        <Grid item>
          <Typography
            sx={{
              cursor: 'pointer',
              [theme.breakpoints.only('xs')]: {
                marginRight: '1.5rem',
              },
            }}
            onClick={onAboutPublicPagesOpen}
            className={classes.link}
          >
            <IconButton
              sx={{ height: '16px', width: '16px', marginRight: '0.5rem' }}
              disableRipple
              variant="outlined"
              aria-label="Help"
              size="medium"
            >
              <ToolTip
                sx={{ height: '16px', width: '16px', color: '#5079A1' }}
              />
            </IconButton>
            What are public pages?
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.main}>
        {artistProfiles.map((ap, index) => (
          <Box className={classes.section} key={index} gap={2}>
            <Grid container gap={2}>
              <Grid item>
                <Typography sx={{ fontWeight: '500' }}>
                  {ap.aliases && ap.aliases?.value?.length ? (
                    <>
                      {ap.aliasShowBoth
                        ? `${ap.artistName} (${ap.aliases?.value[0]})`
                        : ap.aliases.value[0]}
                    </>
                  ) : (
                    `${ap.artistName}`
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ opacity: '0.7' }}>
                  {ap.enabledServices?.some(
                    (es) => es.name === 'publicPage' && !es.disabled
                  )
                    ? 'A public page will be enabled.'
                    : 'This profile will be private.'}
                </Typography>
              </Grid>
            </Grid>
            <Toggler
              checked={ap.enabledServices?.some(
                (es) => es.name === 'publicPage' && !es.disabled
              )}
              onClick={() => handleToggle(ap)}
            />
          </Box>
        ))}
      </div>
      <div
        className={classes.buttons}
        style={{
          marginBottom: '60px',
        }}
      >
        <Button
          isBack
          width={{ xs: '45px', lg: '200px' }}
          type="button"
          onClick={back}
        >
          previous
        </Button>
        <div></div>
        <div className={classes.buttonsNext}>
          {isLoading ? (
            <Button type="button" width="300px">
              Setting up...
            </Button>
          ) : (
            <span>
              {isRedirecting ? (
                <Button type="button" width="300px">
                  Redirecting...
                </Button>
              ) : (
                <Button
                  type="button"
                  onClick={handleSubmit}
                  width={{ xs: '200px', lg: '300px' }}
                >
                  go to your passport
                </Button>
              )}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublicPages;
